import React, { useState, useRef } from "react"
import Gallery from "react-photo-gallery"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "theme-ui"
import Modal from "../modal"
import Button from "../button"

function fischer_yates_shuffle(array) {
  let i = array.length - 1
  for (i; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const imageRenderer = (selectGalleryImage) => ({ photo }) => {
  return (
    <div
      key={photo.key}
      style={{ margin: "2px", width: photo.width, height: photo.height, cursor: 'pointer' }}
      onClick={selectGalleryImage(photo)}
    >
      <Img fixed={photo} key={photo.key} />
    </div>
  )
}

const ImageModal = ({ imageUrl, closeGalleryImage }) => {
  const wrapper = css({
    position: "fixed",
    left: 0,
    top: 0,
    backgroundColor: "rgba(0, 0, 0, 0.95)",
    height: "100vh",
    width: "100vw",
    zIndex: 999,
  })

  const inner = css({
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  })

  const close = css({
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: 'pointer',
  })

  return (
    <Modal>
      <div css={wrapper}>
        <div css={inner}>
          <Button onClick={closeGalleryImage} css={close}>
            Close
          </Button>
          <img src={imageUrl} alt={"our trip"} style={{ maxHeight: '100%' }}/>
        </div>
      </div>
    </Modal>
  )
}

const PhotoGalleryV2 = ({ images }) => {
  const [{ selectedImageUrl }, setState] = useState({})

  function mapNode({ name, publicURL, childImageSharp: { fixed } }) {
    return {
      name,
      publicURL,
      ...fixed,
    }
  }

  const galleryImageRef = useRef((images || []).map(mapNode));

  function selectGalleryImage({ publicURL }) {
    return function() {
      setState({ selectedImageUrl: publicURL })
    }
  }

  function closeGalleryImage() {
    setState({ selectGalleryImage: null })
  }

  return (
    <div style={{ paddingBottom: "2em" }}>
      <Gallery
        renderImage={imageRenderer(selectGalleryImage)}
        photos={galleryImageRef.current
          .map(node => ({
            node,
          }))
          .map(({ node }) => ({
            ...node,
            key: node.name,
            alt: node.name,
          }))}
      />
      {selectedImageUrl && <ImageModal closeGalleryImage={closeGalleryImage} imageUrl={selectedImageUrl} />}
    </div>
  )
}

const PhotoGallery = ({ imageSets }) => {
  const [{ selectedImageUrl }, setState] = useState({})

  imageSets = imageSets || {};

  const data = useStaticQuery(graphql`
    fragment GalleryFile on File {
      name
      publicURL
    }

    query GalleryImages {
      portraits: allFile(
        filter: {
          extension: { eq: "jpeg" }
          childImageSharp: { resolutions: { aspectRatio: { lt: 1 } } }
        }
      ) {
        nodes {
          ...GalleryFile
          childImageSharp {
            fixed(width: 1152) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      landscapes: allFile(
        filter: {
          extension: { eq: "jpeg" }
          childImageSharp: { resolutions: { aspectRatio: { gt: 1 } } }
        }
      ) {
        nodes {
          ...GalleryFile
          childImageSharp {
            fixed(width: 1152) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  `)

  function mapNode({ name, publicURL, childImageSharp: { fixed } }) {
    return {
      name,
      publicURL,
      ...fixed,
    }
  }

  const galleryImageRef = useRef(fischer_yates_shuffle([
    ...data.portraits.nodes.map(mapNode),
    ...data.landscapes.nodes.map(mapNode),
  ]));

  const postFixMatch = Object.keys(imageSets)
    .filter(key => key.endsWith("*"))
    .map(key => key.substr(0, key.length - 1))

  function matchesPostfix(name) {
    const match = postFixMatch.find(key => name.startsWith(key))
    if (match) {
      return galleryImageRef.current.find(galleryItem =>
        galleryItem.name.startsWith(match)
      )
    }
    return null
  }

  function selectGalleryImage({ publicURL }) {
    return function() {
      setState({ selectedImageUrl: publicURL })
    }
  }

  function closeGalleryImage() {
    setState({ selectGalleryImage: null })
  }

  return (
    <div style={{ paddingBottom: "2em" }}>
      <Gallery
        renderImage={imageRenderer(selectGalleryImage)}
        photos={galleryImageRef.current
          .map(node => ({
            node,
            set:
              imageSets[node.name] ||
              matchesPostfix(node.name) ||
              imageSets["*"],
          }))
          .filter(({ set }) => set)
          .map(({ node, set }) => ({
            ...node,
            key: node.name,
            alt: set.description,
          }))}
      />
      {selectedImageUrl && <ImageModal closeGalleryImage={closeGalleryImage} imageUrl={selectedImageUrl} />}
    </div>
  )
}

export default ({ imageSets, images }) => imageSets ? (<PhotoGallery imageSets={imageSets} />) : (<PhotoGalleryV2 images={images} />);