import React, { useState } from "react"
import { css } from "theme-ui"

export default ({ children, disabled, ...props }) => {
  const [{ hovered }, setState] = useState({ hovered: false })

  return (
    <button
      disabled={disabled}
      {...props}
      onMouseEnter={() => setState({ hovered: true })}
      onMouseLeave={() => setState({ hovered: false })}
      css={css({
        border: "2px solid",
        borderColor: disabled ? "muted" : hovered ? "secondary" : "primary",
        color: disabled ? "muted" : hovered ? "secondary" : "primary",
        background: "none",
        cursor: "pointer",
      })}
    >
      {children}
    </button>
  )
}
