import React from "react"
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
  Markers,
  Marker,
} from "react-simple-maps"
import theme from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors"
import { useStaticQuery, navigate, graphql } from "gatsby"
import { css } from "theme-ui"
import merge from "lodash.merge"

const wrapperStyles = css({
  width: "100%",
  maxWidth: "100%",
  margin: "0 auto",
});

function calcBounds(markers) {
  const lats = markers.map(marker => parseFloat(marker.coordinates[0]))
  const longs = markers.map(marker => parseFloat(marker.coordinates[1]))

  const minLat = Math.min(...lats)
  const minLong = Math.min(...longs)
  const maxLat = Math.max(...lats)
  const maxLong = Math.max(...longs)

  return { minLat, minLong, maxLat, maxLong }
}

function findCenter(markers) {
  const { minLat, minLong, maxLat, maxLong } = calcBounds(markers)
  return [(minLat + maxLat) / 2.0, (minLong + maxLong) / 2.0]
}

function zoomToFit(markers, width, height) {
  const halfWidth = width / 2.0
  const halfHeight = height / 2.0

  const { minLat, minLong, maxLat, maxLong } = calcBounds(markers)

  const bounds = [
    [minLat - halfWidth, minLong - halfHeight],
    [maxLat + halfWidth, maxLong + halfHeight],
  ]

  const dx = bounds[1][0] - bounds[0][0]
  const dy = bounds[1][1] - bounds[0][1]

  const zoom = Math.max(dx / width, dy / height)

  return zoom
}

function handleGeographyClick(clickedISO3, posts) {
  return () => {
    const post = posts.find(post => post.keywords.indexOf(clickedISO3) !== -1)
    if (post) {
      navigate(post.slug)
    }
  }
}

function renderGeographyBehavior({
  includedCountries,
  geographies,
  projection,
  removeExcluded,
  posts,
}) {
  const geographyStyle = {
    default: {
      fill: theme.muted,
      stroke: "#607D8B",
      strokeWidth: 0.75,
      outline: "none",
    },
    hover: {
      fill: theme.primary,
      stroke: "#607D8B",
      strokeWidth: 0.75,
      outline: "none",
    },
    pressed: {
      fill: theme.highlight,
      stroke: "#607D8B",
      strokeWidth: 0.75,
      outline: "none",
    },
  }

  if (removeExcluded) {
    return geographies.map(
      (geography, i) =>
        includedCountries.indexOf(geography.properties.ISO_A3) !== -1 && (
          <Geography
            key={i}
            geography={geography}
            projection={projection}
            style={{
              default: geographyStyle.default,
              hover: geographyStyle.default,
              pressed: geographyStyle.default,
            }}
          />
        )
    )
  } else {
    return geographies.map((geography, i) => (
      <Geography
        key={i}
        geography={geography}
        projection={projection}
        onClick={handleGeographyClick(geography.properties.ISO_A3, posts)}
        style={merge({}, geographyStyle, {
          default: {
            fill:
              includedCountries.indexOf(geography.properties.ISO_A3) !== -1
                ? theme.primary
                : theme.muted,
          },
        })}
      />
    ))
  }
}

function convertCitiesToMarkers(cities, cityData) {
  function lookUpCity(city) {
    const row = cityData.find(row => row.city === city)
    if (row) {
      return {
        name: row.city,
        coordinates: [row.latitude, row.longitude],
      }
    }

    console.warn(`Could not find city ${city}`)
    return null
  }

  return cities.map(lookUpCity).filter(a => a !== null)
}

function renderMarkers(marker, key) {
  return (
    <Marker
      key={key}
      marker={marker}
      style={{
        default: { stroke: theme.secondary },
        hover: { stroke: theme.primary },
        pressed: { stroke: theme.muted },
      }}
    >
      <g transform="translate(-12, -24)">
        <path
          fill="none"
          strokeWidth="2"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeLinejoin="miter"
          d="M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z"
        />
        <circle
          fill="none"
          strokeWidth="2"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeLinejoin="miter"
          cx="12"
          cy="9"
          r="3"
        />
      </g>
      <text
        textAnchor="middle"
        y={20}
        style={{
          fontFamily: "Roboto, sans-serif",
          fill: theme.secondary,
          stroke: "none",
        }}
      >
        {marker.name}
      </text>
    </Marker>
  )
}

const FilteredMarkedGeography = ({
  includedCountries,
  removeExcluded,
  render: { width, height, scale },
  cities,
  center: [x, y],
}) => {
  const data = useStaticQuery(graphql`
    query FilteredMarkedGeography {
      allBlogPost {
        nodes {
          keywords
          slug
        }
      }
      cityData: allWorldCitiesCsv {
        nodes {
          latitude
          longitude
          city
        }
      }
      mapData: file(name: { eq: "world-50m" }) {
        publicURL
      }
    }
  `)

  const markers = convertCitiesToMarkers(cities || [], data.cityData.nodes)

  const center = markers.length ? findCenter(markers) : [x || 0, y || 0]

  return (
    <div css={wrapperStyles}>
      <ComposableMap
        projectionConfig={{ scale }}
        height={height}
        width={width}
        style={{
          width: "100%",
          height: "auto",
        }}
      >
        <ZoomableGroup
          center={center}
          disablePanning={removeExcluded}
          zoom={
            markers && markers.length ? zoomToFit(markers, width, height) : 1
          }
        >
          <Geographies geography={data.mapData.publicURL}>
            {(geographies, projection) =>
              renderGeographyBehavior({
                includedCountries,
                geographies,
                projection,
                removeExcluded,
                posts: data.allBlogPost.nodes,
              })
            }
          </Geographies>
          <Markers>{markers.map(renderMarkers)}</Markers>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  )
}

export default FilteredMarkedGeography
