import merge from "lodash.merge"
import defaultThemeColor from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors"

const grey90 = `#232129`
const white = `#fff`
const lightWhite = `rgba(255, 255, 255, 0.86)`
const lightGray = `hsla(0, 0%, 0%, 0.2)`
const tealish = '#14C9CB';
const bluegreenish = "#08B3E5"
const greenblueish = "#22E4AC"

export default merge({}, defaultThemeColor, {
  text: grey90,
  background: white,
  primary: tealish,
  secondary: bluegreenish,
  muted: lightGray,
  highlight: greenblueish,
  heading: tealish,
  prism: {
    background: `#011627`,
    comment: `#809393`,
    string: `#addb67`,
    var: `#d6deeb`,
    number: `#f78c6c`,
    constant: `#82aaff`,
    punctuation: `#c792ea`,
    className: `#ffc98b`,
    tag: `#ffa7c4`,
    boolean: `#ff5874`,
    property: `#80cbc4`,
    namespace: `#b2ccd6`,
    highlight: `hsla(207, 95%, 15%, 1)`,
  },
  modes: {
    dark: {
      text: lightWhite,
      background: grey90,
      primary: bluegreenish,
      secondary: tealish,
      muted: lightGray,
      highlight: greenblueish,
      heading: tealish,
    },
  },
})
