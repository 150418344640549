import React from "react"
import { Styled, css } from "theme-ui"

export default ({ startDate, endDate, year, visited }) => {
  const Group = ({ width, children }) => (
    <div
      css={css({
        borderWidth: "2px",
        borderLeftColor: 'primary',
        borderLeftStyle: 'dashed',
        paddingRight: [0, 0, "1em"],
        flex: `1 ${width}`,
      })}
    >
      <div
        css={css({
          margin: 0,
          padding: 0,
          marginLeft: "1em",
        })}
      >
        {children}
      </div>
    </div>
  )

  const visitedContent =
    visited && visited.length ? (
      <Group width={"70%"}>
        <Styled.h2>Visited</Styled.h2>
        <Styled.h3>{visited.join(", ")}</Styled.h3>
      </Group>
    ) : null

  const dateContent = (
    <Group width={"20%"}>
      <Styled.h1 css={css({ color: "primary" })}>{year}</Styled.h1>
      From<Styled.h3>{startDate}</Styled.h3>To
      <Styled.h3>{endDate}</Styled.h3>
    </Group>
  )

  return (
    <div
      css={css({ display: "flex", flexFlow: "row wrap", marginBottom: "3em" })}
    >
      <div
        css={css({
          display: ["none", "none", "flex"],
          borderRadius: "50%",
          borderColor: "primary",
          backgroundColor: "primary",
          width: "2em",
          height: "2em",
          flex: "1 10%",
          maxWidth: "2em",
          maxHeight: "2em",
          marginRight: "3em",
          alignSelf: "center",
        })}
      ></div>
      {dateContent}
      {visitedContent}
    </div>
  )
}
