import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import PhotoGallery from "../../photo-gallery"

export default () => {
  const data = useStaticQuery(graphql`
    fragment GalleryFile on File {
      name
      publicURL
    }
    
    query BostonWalkGalleryPageImages {
      images: allFile(
        filter: {
          extension: { eq: "jpeg" }
          name: { regex: "/^boston-walk-.*/" }
        }
      ) {
        nodes {
          ...GalleryFile
          childImageSharp {
            fixed(width: 1152) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }`
  );
  
  return (<PhotoGallery images = { data.images.nodes }/>)
}