import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { document } from 'browser-monads'

const modalRoot = document.getElementById('___gatsby')

export default ({ children }) => {
  const el = document.createElement('div')

  useEffect(() => {
    modalRoot.appendChild(el)
    return () => modalRoot.removeChild(el)
  })

  return ReactDOM.createPortal(children, el);
}
