import React from "react"
import { Styled, css } from "theme-ui"

export default ({ date }) => (
  <div
    css={css({
      display: "flex",
      flexFlow: "row wrap",
      borderLeftWidth: "2px",
      borderLeftColor: 'primary',
      borderLeftStyle: "dashed",
      borderTopWidth: "2px",
      borderTopColor: 'primary',
      borderTopStyle: "dashed",
      marginTop: "0.5em",
      marginBottom: "1em",
      marginLeft: "0.95em",
      paddingTop: "0.5em"
    })}
  >
    <div
      css={css({
        paddingRight: "1em",
        flex: `1 90%`,
        alignSelf: "center",
      })}
    >
      <div
        css={css({
          margin: 0,
          padding: 0,
          marginLeft: "1em",
        })}
      >
        <Styled.h3
          css={css({ flex: "1", display: "inline-flex", marginBottom: "0" })}
        >
          {date}
        </Styled.h3>
      </div>
    </div>
  </div>
)
