import FilteredMarkedGeography from "../../../../src/components/filtered-marked-geography";
import Itinerary from "../../../../src/components/itinerary";
import DateSection from "../../../../src/components/date-section";
import { BostonFoodGallery, BostonPaxGallery, BostonPlacesGallery, BostonBeerGallery, BostonWalkGallery } from "../../../../src/components/galleries/boston";
import React from 'react';
export default {
  FilteredMarkedGeography,
  Itinerary,
  DateSection,
  BostonFoodGallery,
  BostonPaxGallery,
  BostonPlacesGallery,
  BostonBeerGallery,
  BostonWalkGallery,
  React
};